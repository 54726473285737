import React from 'react';

const DeployButton = () => {
  const triggerWorkflow = async () => {
    const repoOwner = 'TKELuke';
    const repoName = 'cascadere-njs';
    const workflowName = 'firebase-deploy.yml';
    // Need a new token? https://github.com/settings/tokens
    const githubToken = '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'; //Only good until 11/14/24

    try {
      const response = await fetch(
        `https://api.github.com/repos/${repoOwner}/${repoName}/actions/workflows/${workflowName}/dispatches`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${githubToken}`,
            Accept: 'application/vnd.github.v3+json',
          },
          body: JSON.stringify({
            ref: 'main', // or your desired branch
          }),
        }
      );

      if (response.ok) {
        console.log('Workflow triggered successfully!');
      } else {
        console.error('Failed to trigger workflow:', response.statusText);
      }
    } catch (error) {
      console.error('Error triggering workflow:', error.message);
    }
  };

  return (
      <>
        <div className="info">
          This will deploy the changes by doing a build via GitHub Action.<br/>
          This will take about 5min to complete and be visible on the site.
        </div>
        <button onClick={triggerWorkflow}>
          Deploy Changes
        </button>
      </>
  );
};

export default DeployButton;