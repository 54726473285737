import Header from "../Header";
import LeftNav from "../LeftNav";
import DeployButton from "../DeployButton";
import React from "react";


const BasePage = (props) => {
    return (
        <>
            <Header/>
            <div className="mainContainer">
                <LeftNav />
                <div className="content">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default BasePage;