import React from 'react';
import '../styles/leftnav.css';
const LeftNav = () => {
    return (
        <nav className="leftNav">
            <ul>
                <li><a href="/home">Home</a></li>
                <li><a href="/blogs">Blogs</a></li>
                <li><a href="/properties">Properties</a></li>
                <li><a href="/services">Services</a></li>
            </ul>
        </nav>
    );
};

export default LeftNav;