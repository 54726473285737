import React, { useState, useRef } from 'react';

const EditableText = ({ id, property, type, text, onSave, defaultValue, multiline }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);
    const [updatedText, setUpdatedText] = useState(text);
    const inputRef = useRef(null);

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setEditedText(e.target.value);
    };

    const handleSave = () => {
        const castedValue = castValue(editedText);
        const data = {[property]: castedValue};
        onSave(id, data);
        setUpdatedText(editedText);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setEditedText(text);
        setIsEditing(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
        if (e.key === 'Escape') {
            handleCancel();
        }
    };

    // Set focus on the input when in editing mode
    const setFocus = () => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const castValue = (value) => {
        switch (type) {
            case 'number':
                return Number(value);
            case 'boolean':
                return value.toLowerCase() === 'true';
            // Add more cases as needed
            default:
                return value;
        }
    };

    // Automatically set focus when component mounts or enters editing mode
    React.useEffect(() => {
        if (isEditing) {
            setFocus();
        }
    }, [isEditing]);

    return (
        <span onClick={handleDoubleClick}>
            {isEditing ? (<>
                    {multiline === "true" ? (
                        <textarea
                            type="text"
                            value={editedText}
                            onChange={handleInputChange}
                            onKeyUp={handleKeyPress}
                            ref={inputRef}
                        />
                        ) : (
                        <input
                            type="text"
                            value={editedText}
                            onChange={handleInputChange}
                            onKeyUp={handleKeyPress}
                            ref={inputRef}
                        />
                    )}
            </>) : (<>
                {text? (updatedText? updatedText: text) : defaultValue}
                </>)}
        </span>
    );
};

export default EditableText;
