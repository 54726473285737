import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {signInWithPopup, onAuthStateChanged, signOut} from "firebase/auth";
import React from "react";

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({prompt: 'select_account'});

const firebaseConfig = {
    apiKey: "AIzaSyCRcGnyn53Qtmw437Zb5msXjxkArEOzC70",
    authDomain: "cascadere-e2ca4.firebaseapp.com",
    projectId: "cascadere-e2ca4",
    storageBucket: "cascadere-e2ca4.appspot.com",
    messagingSenderId: "403943855725",
    appId: "1:403943855725:web:bab656dc098f5bd10cd164",
    measurementId: "G-J324MY0BEH"
};

export const firebaseTables = {
    properties: 'properties'
}

export const rentecConfigs = {
    apiKey:  'eyJzdWIiOiI1NDQyNSIsImlhdCI6IjIwMjMtMTEtMjAgMjE6MjA6NDAiLCJpc3MiOiJKU09OIExpc3RpbmdzIn0'
}

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
//export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

// Function to sign in with Google
export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        saveUserToStorage(user);
        return user;
    } catch (error) {
        console.error('Error signing in with Google:', error);
        throw error;
    }
};

// Function to sign out
export const signOutUser = async () => {
    try {
        await signOut(auth);
        clearUserFromStorage();
    } catch (error) {
        console.error('Error signing out:', error);
        throw error;
    }
};

// Function to observe authentication state changes
export const observeAuthState = (setUser) => {
    // Try to load user from storage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
        setUser(storedUser);
    }

    // Start observing authentication state changes
    return onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
            saveUserToStorage(user);
        } else {
            // Clear user from storage on sign out
            clearUserFromStorage();
        }
    });
};

const saveUserToStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const getUserFromStorage = () => {
    console.log("I tried");
    return localStorage.getItem('user');
};

const clearUserFromStorage = () => {
    localStorage.removeItem('user');
};

export const firestore = firebase.firestore();



export default firebase;