import React from 'react';
import '../styles/header.css'
import {auth, signOutUser} from "../services/firebase";
import {Navigate, useNavigate} from "react-router-dom";
const Header = ({ user }) => {
    const navigate = useNavigate();
    return (
        <header className="header">
            <h1>Cascadere Authoring Interface</h1>
            <div>
                {user && (<img src={user.photoURL} alt="" />)}<br />
                <button className="button signout" onClick={() => {
                    signOutUser().then(r =>  navigate("/"));
                }}>Sign out</button>
            </div>
        </header>
    );
};

export default Header;