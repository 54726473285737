import React, { useState } from "react";
import EditableText from "./EditableText";

//label="For Rent" property="isForRent" value={property.isForRent} onChange
const EditableArray = ({ id, label, property, arrayData, onSave, defaultValue }) => {
    const [arrayValues, setArrayValues] = useState(arrayData ? arrayData : []);
    const [newValue, setNewValue] = useState('');

    const handleAddValue = async () => {
        const arrayWithNewValue = [...arrayValues, newValue];
        const data = {[property]: arrayWithNewValue};
        setArrayValues(arrayWithNewValue);
        setNewValue('');
        onSave(id, data);
    };

    //itemId is just ignored, this is for EditableText response
    const handleSaveItemChanges = async (itemId, data) => {
        const dataKeys = Object.keys(data);
        const dataNewArray = arrayValues;
        dataNewArray[dataKeys[0]] = data[dataKeys[0]];
        const dataNew = {[property]: [...dataNewArray]};
        onSave(id, dataNew);
    }

    return (
        <div>
            <div className="editable-array-label">{label}</div>
            <ul>
                {arrayValues.map((value, index) => (
                    /* Change to use EditableText */
                    <li key={index}><EditableText
                        id={property.id}
                        property={index}
                        type="text"
                        text={value}
                        onSave={handleSaveItemChanges}
                        defaultValue={defaultValue}
                        multiline="false"
                    /></li>
                ))}
            </ul>
            <input type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
            <button onClick={handleAddValue}>Add Value</button>
        </div>
    );
};

export default EditableArray;
