import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {firebaseTables, firestore, rentecConfigs} from "../../services/firebase";
import { updateDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../../styles/properties.css';
import Header from "../Header";
import LeftNav from "../LeftNav";
import BasePage from "./BasePage";
import classNames from "classnames";
import EditableText from "../EditableText";
import EditableCheckbox from "../EditableCheckbox";
import EditableArray from "../EditableArray";

const Properties = () => {
    const [properties, setProperties] = useState([]);
    const [property, setProperty] = useState('');
    const [rentecData, setRentecData] = useState([]);
    const [loadingRentec, setLoadingRentec] = useState(true);
    const [filters, setFilters] = useState({
        isComingSoon: false,
        isForRent: false,
        isRented: false,
        isForSale: false,
        isPending: false,
        isSold: false,
        isFeatured: false
    });
    const navigate = useNavigate();

    const fetchProperties=async()=>{
        const response = firestore.collection(firebaseTables.properties);
        const data = await response.get();

        const documents = data.docs.map(doc => ({
            key: doc.id,
            id: doc.id,
            ...doc.data(),
        }));
        setProperties(documents);
    };

    const fetchRentecData = async () => {
        try {
            const response = await axios.get('https://secure.rentecdirect.com/owners/utilities/property_listings_JSON.php?key='+rentecConfigs.apiKey);
            setRentecData(response.data.properties);
            setLoadingRentec(false);
        } catch (error) {
            console.error('Error fetching rentec data:', error);
            setLoadingRentec(false);
        }
    };

    const processRentecDetails = async () => {
        try {
            if(rentecData) {
                const updatedArray = rentecData.map(rentecProperty => {
                    const prop = findPropertyByValues(rentecProperty.property_id);
                    console.info('=====> property found: ' + prop.key);
                    if(prop.key) {
                        rentecProperty.webId = prop.key;
                        console.info('=====> set webId: ' + rentecProperty.webId);
                    }
                    return rentecProperty;
                });
                console.info('+++++  =====> Update Rentec Data: ' + updatedArray);
                setRentecData(updatedArray);
            }
            setLoadingRentec(false);
        } catch (error) {
            console.error('Error fetching rentec data:', error);
            setLoadingRentec(false);
        }
    };

    function findPropertyByValues(property_id) {
        console.info('findPropertyByValues');
        if(!property_id || property_id === '') {
            console.error('Value provided for property_id were invalid');
            return undefined;
        }
        if(!properties || properties === ''){
            console.error('No properties loaded');
            return undefined;
        }
        console.info('Looping Props');
        for( const prop of properties ){
            console.info(prop.name + ' has an rentec ID as ' + prop.rentecId);
            if(property_id == prop.rentecId){
                return prop;
            };
        };
        return undefined;
    }


    useEffect(() => {
        fetchProperties();
        fetchRentecData();
        processRentecDetails();
    }, []);

    const handleAddProperty = () => {
        const id = property.toLowerCase().replace(/[^0-9a-z]/g, '-');
        firestore.collection(firebaseTables.properties).doc(id).set({
            name: property,
            isComingSoon: false,
            isForRent: false,
            isRented: false,
            isForSale: false,
            isPending: false,
            isSold: false,
            isFeatured: false
        })
            .then(() => {
                console.log('Property added successfully.');
                setProperty('');
                fetchProperties();
            })
            .catch((error) => {
                console.error('Error adding property:', error);
            });
    };

    const handleAddPropertyFromRentec = (rentecProp) => {
        const id = rentecProp.address.toLowerCase().replace(/[^0-9a-z]/g, '-');
        const newImageArray = rentecProp.images.map(image => {
           return image.imagelink;
        });

        firestore.collection(firebaseTables.properties).doc(id).set({
            name: rentecProp.address,
            isComingSoon: true,
            isForRent: false,
            isRented: false,
            isForSale: false,
            isPending: false,
            isSold: false,
            isFeatured: false,
            streetOne: rentecProp.address,
            city: rentecProp.city,
            state: rentecProp.state,
            zip: rentecProp.zip,
            description: rentecProp.class_ad,
            beds: rentecProp.bedrooms,
            baths: rentecProp.bathrooms,
            image: rentecProp.adpic_link,
            images: newImageArray,
            application: rentecProp.app_link,
            rentecId: rentecProp.property_id
        })
            .then(() => {
                console.log('Property added successfully.');
                setProperty('');
                fetchProperties();
            })
            .catch((error) => {
                console.error('Error adding property:', error);
            });
    };

    const handleUpdate = async(id, data) => {
        // Handle saving the edited text
        const doc = firestore.collection(firebaseTables.properties).doc(id);
        await updateDoc(doc, data);
        fetchProperties();
    };

    const handleRemoveProperty = async(id) => {
        // Handle saving the edited text
        const doc = firestore.collection(firebaseTables.properties).doc(id);
        await doc.delete();
        fetchProperties();
    };

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: !prevFilters[filterName]
        }));
    };

    const filteredProperties = properties.filter(property => {
        return (
            (!filters.isComingSoon || property.isComingSoon) &&
            (!filters.isForRent || property.isForRent) &&
            (!filters.isRented || property.isRented) &&
            (!filters.isForSale || property.isForSale) &&
            (!filters.isPending || property.isPending) &&
            (!filters.isSold || property.isSold) &&
            (!filters.isFeatured || property.isFeatured)
        );
    });

    return (
        <BasePage>
            <h2>Properties</h2>
            <div>
                Click on the text to update.<br/>
                'Enter' to save<br/>
                'Esc' to cancel<br />
                **Only Update One Field At A Time**<p/>
            </div>
            <div>
                <input type="text" value={property} onChange={(e) => setProperty(e.target.value)} placeholder="Property name" />
                <button onClick={handleAddProperty} className="addPropertyButton">Add Property</button>
            </div>
            <p />
            <div className="sticky-filters">
                <p>Filter by:</p>
                <EditableCheckbox label="Coming Soon" value={filters.isComingSoon} onChange={() => handleFilterChange('isComingSoon')} />
                <EditableCheckbox label="For Rent" value={filters.isForRent} onChange={() => handleFilterChange('isForRent')} />
                <EditableCheckbox label="Rented" value={filters.isRented} onChange={() => handleFilterChange('isRented')} />
                <EditableCheckbox label="For Sale" value={filters.isForSale} onChange={() => handleFilterChange('isForSale')} />
                <EditableCheckbox label="Pending" value={filters.isPending} onChange={() => handleFilterChange('isPending')} />
                <EditableCheckbox label="Sold" value={filters.isSold} onChange={() => handleFilterChange('isSold')} />
                <EditableCheckbox label="Featured" value={filters.isFeatured} onChange={() => handleFilterChange('isFeatured')} />
            </div>
            <div>
                {
                    filteredProperties && filteredProperties.map(property=>{
                        return(
                            <div key={property.name} className={classNames('properties-container', {
                                featured: property.isFeatured,
                                comingSoon: property.isComingSoon ? property.isComingSoon : false,
                                forRent: property.isForRent ? property.isForRent : false,
                                rented: property.isRented ? property.isRented : false,
                                forSale: property.isForSale ? property.isForSale : false,
                                pending: property.isPending ? property.isPending : false,
                                sold: property.isSold ? property.isSold : false,
                                offMarket: (!property.isComingSoon && !property.isForRent && !property.isRented && !property.isForSale && !property.isPending && !property.isSold)
                            })}
                            >
                                <div className="propertyDataContainerWrapper">
                                    <div className="propertyDataContainer">
                                        <h3><EditableText id={property.id} property="name" type="string" text={property.name} onSave={handleUpdate}/></h3>
                                        <p><EditableCheckbox id={property.id} label="Coming Soon (Rental)" property="isComingSoon" value={property.isComingSoon} onChange={handleUpdate}/>
                                            <EditableCheckbox id={property.id} label="For Rent" property="isForRent" value={property.isForRent} onChange={handleUpdate}/>
                                            <EditableCheckbox id={property.id} label="Rented" property="isRented" value={property.isRented} onChange={handleUpdate}/><br/>
                                            <EditableCheckbox id={property.id} label="For Sale" property="isForSale" value={property.isForSale} onChange={handleUpdate}/>
                                            <EditableCheckbox id={property.id} label="Pending" property="isPending" value={property.isPending} onChange={handleUpdate}/>
                                            <EditableCheckbox id={property.id} label="Sold" property="isSold" value={property.isSold} onChange={handleUpdate}/><br/>
                                            <EditableCheckbox id={property.id} label="Is Featured" property="isFeatured" value={property.isFeatured} onChange={handleUpdate}/><br/></p>
                                        <p>Rentec ID: {property.rentecId}</p>
                                        <p><EditableText
                                            id={property.id}
                                            property="headline"
                                            type="string"
                                            text={property.headline}
                                            onSave={handleUpdate}
                                            defaultValue="No headline provided"
                                            multiline="false"
                                        /></p>
                                        <p><EditableText
                                            id={property.id}
                                            property="description"
                                            type="string"
                                            text={property.description}
                                            onSave={handleUpdate}
                                            defaultValue="No description provided"
                                            multiline="true"
                                        /></p>
                                        <EditableArray
                                            id={property.id}
                                            label="Description Bullets:"
                                            property="descriptionBullets"
                                            type="string"
                                            arrayData={property.descriptionBullets}
                                            onSave={handleUpdate}
                                            defaultValue="No description bullets provided"
                                        />
                                        <p>Price: <EditableText
                                            id={property.id}
                                            property="price"
                                            type="string"
                                            text={property.price}
                                            onSave={handleUpdate}
                                            defaultValue="$100,000"
                                            multiline="false"
                                        /></p>
                                        <div className="properties-addr">
                                            Address:<br />
                                            <EditableText
                                                id={property.id}
                                                property="streetOne"
                                                type="string"
                                                text={property.streetOne}
                                                onSave={handleUpdate}
                                                defaultValue="Address Line 1"
                                                multiline="false"
                                            /> <br />
                                            <EditableText
                                                id={property.id}
                                                property="streetTwo"
                                                type="string"
                                                text={property.streetTwo}
                                                onSave={handleUpdate}
                                                defaultValue="Address Line 2"
                                                multiline="false"
                                            /> <br />
                                            <EditableText
                                                id={property.id}
                                                property="city"
                                                type="string"
                                                text={property.city}
                                                onSave={handleUpdate}
                                                defaultValue="City"
                                                multiline="false"
                                            />, <EditableText
                                            id={property.id}
                                            property="state"
                                            type="string"
                                            text={property.state}
                                            onSave={handleUpdate}
                                            defaultValue="State"
                                            multiline="false"
                                        /> <EditableText
                                            id={property.id}
                                            property="zip"
                                            type="string"
                                            text={property.zip}
                                            onSave={handleUpdate}
                                            defaultValue="Zip"
                                            multiline="false"
                                        /><br />
                                        </div>
                                        <p>Beds: <EditableText
                                            id={property.id}
                                            property="beds"
                                            type="number"
                                            text={property.beds}
                                            onSave={handleUpdate}
                                            defaultValue="0"
                                            multiline="false"
                                        /></p>
                                        <p>Baths: <EditableText
                                            id={property.id}
                                            property="baths"
                                            type="number"
                                            text={property.baths}
                                            onSave={handleUpdate}
                                            defaultValue="0"
                                            multiline="false"
                                        /></p>
                                        <p>Application URL: <EditableText
                                            id={property.id}
                                            property="application"
                                            type="string"
                                            text={property.application}
                                            onSave={handleUpdate}
                                            defaultValue="No URL"
                                            multiline="false"
                                        /></p>
                                        <p>YouTube ID: <EditableText
                                            id={property.id}
                                            property="video"
                                            type="string"
                                            text={property.video}
                                            onSave={handleUpdate}
                                            defaultValue="Video ID like Sb5DL3ISdYDA8"
                                            multiline="false"
                                        /></p>
                                        <div className="main-image-container">
                                            <div className="main-image-editable-container">
                                                <p>Main Image URL: <EditableText
                                                    id={property.id}
                                                    property="image"
                                                    type="string"
                                                    text={property.image}
                                                    onSave={handleUpdate}
                                                    defaultValue="Image URL like https://www.site.com/image.jpg"
                                                    multiline="false"
                                                /></p>
                                            </div>
                                            <div className="main-image-image-container">
                                                <img
                                                    className="main-image-image"
                                                    src={property.image} />
                                            </div>
                                        </div>
                                        <div className="image-array-container">
                                            <div className="image-array-editable-container">
                                                <EditableArray
                                                    id={property.id}
                                                    label="Images:"
                                                    property="images"
                                                    type="string"
                                                    arrayData={property.images}
                                                    onSave={handleUpdate}
                                                    defaultValue="No images provided"
                                                />
                                            </div>
                                            <div className="image-array-image-container">
                                                { property.images && property.images.map(image=>{
                                                    return(
                                                        <img
                                                            height="200px"
                                                            width="300px"
                                                            className="image-array-image"
                                                            src={image} />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => handleRemoveProperty(property.id)} className="removePropertyButton">Remove Property</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <h3>Rentec List:</h3>
            {loadingRentec ? (
                <p>Loading...</p>
            ) : (
                <ul>
                    {rentecData && rentecData.map((item) => (
                        <li key={item.property_id}>{item.property_id} - {item.address} - {item.webId}
                            <button onClick={() => handleAddPropertyFromRentec(item)} className="addPropertyButton">Add Property</button>
                        </li>
                    ))}
                </ul>
            )}
        </BasePage>
    );
};

export default Properties;