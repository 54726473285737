import React, {useEffect, useState} from 'react';
import Login from './components/pages/Login';
import Home from './components/pages/Home';
import firebase, {firestore} from './services/firebase';
import './App.css';
import './styles/main.css';
import Header from "./components/Header";
import LeftNav from "./components/LeftNav";
import Routes from "./MainRouter";
import MainRouter from "./MainRouter";
import {AuthProvider} from "./Auth";
import {BrowserRouter as Router, Route} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Properties from "./components/pages/Properties";
import Blogs from "./components/pages/Blogs";
import Services from "./components/pages/Services";

// SignUp component
/*const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = () => {
    auth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          console.log('Signed up user:', user);
        })
        .catch((error) => {
          // Handle errors here
          console.error('Error signing up:', error);
        });
  };

  return (
      <>
          <div>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <button onClick={handleSignUp}>Sign Up</button>
          </div>
          <div>
              <button className="button" onClick={signInWithGoogle}><i className="fab fa-google"></i>Sign in with google</button>
          </div>
      </>
  );
};
*/

// App component
const App = () => {

  return (
      <AuthProvider>
          <MainRouter />
      </AuthProvider>
  );
};

export default App;