import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import firebase from './services/firebase';
import Login from './components/pages/Login';
import Home from './components/pages/Home';
import Properties from './components/pages/Properties';
import Blogs from "./components/pages/Blogs";
import Services from "./components/pages/Services";
import {AuthContext, AuthProvider} from "./Auth";

const PrivateRoute = ({ element: Element }) => {
    const {currentUser} = useContext(AuthContext);
    if (!currentUser) {
        console.log("No User! Go to /")
        // Redirect to the login page if the user is not authenticated
        return <Navigate to="/"/>;
    }

    console.log("User: " + currentUser.displayName + " Going to: " +  Element.name);
    return <Element />;
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/home" element={<PrivateRoute element={Home} />} />
            <Route path="/properties" element={<PrivateRoute element={Properties} />} />
            <Route path="/blogs" element={<PrivateRoute element={Blogs} />} />
            <Route path="/services" element={<PrivateRoute element={Services} />} />
        </Routes>
    );
};

const MainRouter = () => {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
};

export default MainRouter;