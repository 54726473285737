import React from 'react';

import { auth } from '../../services/firebase'

import '../../App.css';
import Properties from "./Properties";
import DeployButton from "../DeployButton";
import Header from "../Header";
import LeftNav from "../LeftNav";
import BasePage from "./BasePage";

const Home = ({ user }) => {
    return (
        <BasePage>
            <DeployButton />
        </BasePage>
    )
}

export default Home;