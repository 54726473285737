import React from 'react';
import BasePage from "./BasePage";

const Blogs = () => {
    return (
        <BasePage>
            <h1>Blogs</h1>
            {/* Your blogs content goes here */}
        </BasePage>
    );
};

export default Blogs;