import React from 'react';
import BasePage from "./BasePage";

const Services = () => {
    return (
        <BasePage>
            <h1>Services</h1>
            {/* Your services content goes here */}
        </BasePage>
    );
};

export default Services;