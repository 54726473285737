import React, { useState } from "react";

//label="For Rent" property="isForRent" value={property.isForRent} onChange
const EditableCheckbox = ({ id, label, property, value, onChange }) => {
    const [checked, setChecked] = useState(value);

    const handleChange = () => {
        const data = {[property]: !checked};
        setChecked(!checked);
        onChange(id, data);
    };

    return (
        <>
            <span className="checkbox-label">{label}</span>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
            />
        </>
    );
};

export default EditableCheckbox;
