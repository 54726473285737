import React, {useContext, useEffect, useState} from 'react';
import { signInWithPopup } from 'firebase/auth';
import firebase, {auth, googleProvider, signInWithGoogle, observeAuthState} from '../../services/firebase';
import { useNavigate } from 'react-router-dom';

import '../../App.css';
import {AuthContext} from "../../Auth";

const Login = () => {
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const user = await signInWithGoogle();
            console.log("User:" + user.displayName);
            navigate('/home');
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    }

    return (
        <div className="login-container">
            <div>
                <button className="login-button" onClick={handleLogin}><i className="fab fa-google"></i>SIGN IN</button>
            </div>
        </div>
    )
}

export default Login;